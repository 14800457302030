






import { Component, Vue } from "vue-property-decorator"
import GlobalFooter from '@/components/global/footer.vue'

@Component({
    name: 'member',
    components: {
      GlobalFooter
    }
})
export default class Member extends Vue {

}
